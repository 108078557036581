<!--
    内容：门店列表
    作者：ada-xu
-->
<template>
    <div class='page scroll'>
        <div class="filter-head">
            <van-search
              v-model="keyword"
              placeholder="请输入搜索内容"
              show-action
              @search="onSearch">
              <template #action>
                <div @click="onSearch">搜索</div>
              </template>
            </van-search>
        </div>
        <div class="list">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad">
                <div class="item"  v-for="(item,index) in list" :key="index" @click="handleDetail(item)">
                    <img :src="item.coverFullUrl" class="store-logo" v-if="item.coverFullUrl">
                    <img src="@/assets/img/logo@2x.png" class="store-logo" v-else>
                    <div class="padding-16">
                        <h4 class="store-name">{{item.storeName}}</h4>
                        <p class="store-addr text-wrap-2">{{item.address}}</p>
                        <ul>
                            <li>
                                <img src="@/assets/img/home_icon_zan@2x.png" alt="">
                                <span>{{item.thumbsCnt}}</span>
                            </li>
                            <li @click="callMobile(item.mobile)">
                                <img src="@/assets/img/home_icon_dianh@2x.png" alt="">
                                <span>电话</span>
                            </li>
                            <li v-if="item.distance">
                                <img src="@/assets/img/home_icon_juli@2x.png" alt="">
                                <span>{{item.distance}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </van-list>
        </div>
    </div>
</template>
<script>
import ajax from '@/api/index';
import { loadBMap } from '@/assets/js/loadBMap';
export default {
    name:'shop' ,
    data(){
        return {
            keyword : "",
            list: [],
            //加载状态结束
            loading: false,
            //是否已完成加载
            finished: false,
            page : 1,
            pageSize : 10,
            //当前位置经度
            longitude : '',
            //当前位置纬度
            latitude : '',
            flag : false,
        }
    },    
    created() {
        window.initBaiduMapScript = () =>{
            this.getlocation();
        }
        loadBMap('initBaiduMapScript');
    },
    methods : {    
        // 异步更新数据
        onLoad() {
            if (!this.flag) {
                return;
            }
            ajax.post('qryStoreList',{
                longitude : this.longitude,//当前位置经度 #首页打开门店页面获取当前手机坐标
                latitude :  this.latitude,//当前位置维度
                page : this.page,
                pageSize : this.pageSize,
                searchKey : this.keyword,
                sortField :"thumbs",//指定排序字段
                sortDirection :"asc",//排序方式  asc|desc
            }).then(res=>{
                if (res.responeCode==='0') {
                  // 加载状态结束
                  this.loading = false;
                  let data = res.data || [];
                  this.list = [...this.list,...data];
                  if(data.length<this.pageSize){
                    this.finished = true;
                  }
                } else {
                  this.$toast(res.responeMsg || '加载失败')
                }
            })
        },
        //查看详情
        handleDetail(row){
            sessionStorage.setItem('shopDetailInfo',JSON.stringify(row));
            //组件shopDetail添加缓存
            this.$store.commit('removeKeepAlive','shopDetail');
            this.$router.push({
              name : 'detail'
            })
        },
        //触发查询
        onSearch(){
            this.page = 1;
            this.list = [];
            this.onLoad();
        },
        //呼叫电话
        callMobile(phoneNumber){
            window.location.href = 'tel://' + phoneNumber
        },
        /**
         * 获取用户地理位置
         */
        getlocation(){
            let that = this;
            try {
                const geolocation = new BMap.Geolocation();
                geolocation.getCurrentPosition(function(r){
                    that.longitude = r.longitude;
                    that.latitude = r.latitude;
                    that.flag = true;
                    that.onLoad();
                });
            } catch(e) {
                that.flag = true;
                that.onLoad();
                console.log(e)
            }
      }
    },
}
</script>
<style scoped lang='scss'>
.page{
  margin-bottom: 120px;
}
.van-search{
  border-radius: 12px;
  padding:5px 0;
}
.van-search__content{
  background-color: transparent;
  color: #ccc;
}
.van-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // padding-bottom: 100px;
  .item {
    width: 340px;
    flex-shrink: 0;
    background-color: #fff;
    margin-top: 24px;
    border-radius: 12px;
    .store-logo{
      width: 340px;
      height: 340px;
      border-radius: 12px 12px 0 0;
      display: block;
    }
    .store-name{
      font-weight: bold;
      font-size: 28px;
      color: #333;
      margin-bottom: 12px;
    }
    .store-addr{
      color: #666;
      margin-bottom: 20px;
      height: 60px;
    }
    .padding-16 {
      padding:16px;
    }
    ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        li{
          display: flex;
          align-items: center;
        }
        img{
          height: 25px;
          margin-right: 10px;
        }
        span{
          color: #999;
          font-size: 20px;
        }
    }
  }
}
/deep/ .van-list__finished-text{
  width: 100%;
}
</style>