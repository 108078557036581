<!--
    内容：门店详情
    作者：ada-xu
-->
<template>
    <div class='page scroll'>
        <div class="store-detail">
            <div class="top">
                <img :src="detail.coverFullUrl" class="store-logo" v-if="detail.coverFullUrl">
                <img src="@/assets/img/logo@2x.png" class="store-logo" v-else>
                <div class="right-box">
                    <h4 class="store-name">{{detail.storeName}}</h4>
                    <p class="store-addr text-wrap-2">{{detail.address}}</p>
                    <ul>
                        <li @click="handleThumb()">
                            <img src="@/assets/img/home_icon_zan_red@2x.png" alt="" v-if="thunbSuccess">
                            <img src="@/assets/img/home_icon_zan@2x.png" alt="" v-else>
                            <span>{{detail.thumbsCnt}}</span>
                        </li>
                        <li @click="callMobile(detail.mobile)">
                            <img src="@/assets/img/home_icon_dianh@2x.png" alt="">
                            <span>电话</span>
                        </li>
                        <li>
                            <img src="@/assets/img/home_icon_juli@2x.png" alt="">
                            <span>{{detail.distance}}</span>
                        </li>
                        <li @click="handleShare()">
                            <img src="@/assets/img/mdxq_icon_fenx@2x.png" alt="">
                            <span>分享</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="intro">
                {{detail.introduction}}
            </div>
        </div>

        <div class="service-items">
            <div class="title">
                <img src="@/assets/img/line-left.png" alt="" class="line">
                <span>服务项目</span>
                <img src="@/assets/img/line-right.png" alt="" class="line">
            </div>
            <div class="list" v-if="tbData.length>0">
                <div class="item" v-for="(item,index) in tbData" :key="index">
                    <img :src="detail.coverFullUrl" v-if="item.coverFullUrl">
                    <img src="@/assets/img/logo@2x.png" v-else>
                    <div class="service-info">
                        <h4>{{item.name}}</h4>
                        <p class="remark">{{item.introduction}}</p>
                    </div>
                </div>
            </div>
            <van-empty description="无记录" v-if="tbData.length===0"/>
        </div>
    </div>
</template>
<script>
import ajax from '@/api/index';
import wx from 'weixin-js-sdk';
export default {
    name:'shopDetail',
    data(){
        return {
            //门店详情
            detail : {},
            //项目列表
            tbData:[],
            //是否点赞成功
            thunbSuccess : false,
        }
    } ,
    created(){
        this.tbData = [];
        let session = JSON.parse(sessionStorage.getItem('shopDetailInfo'));
        if (session) {
            this.detail = session;
            this.queryProjectList();
        } else {
            this.$router.push({
                name : 'shop'
            })
        }
    },
    methods : {
        /**
         * 查询门店会员的项目列表
         */
        queryProjectList(){
            ajax.post('qryProjectPage',{
                id : this.detail.id,
            }).then(res=>{
                if(res.responeCode==='0'){
                    this.tbData = res.data || [];
                }else{
                    this.$toast(res.responeMsg || '操作失败');
                }
            })
        },
        //呼叫电话
        callMobile(phoneNumber){
            if (phoneNumber) {
                window.location.href = 'tel://' + phoneNumber;
            }
        },
        /**
         * 跳转到员工列表
         * @param row 某行数据
         */
        handleDetail(row){
            // 不在这里预约
            // //组件shopDetail添加缓存
            // this.$store.commit('addKeepAlive','shopDetail');
            // //组件selectStaff移除缓存
            // this.$store.commit('removeKeepAlive','selectStaff');
            //
            // this.$router.push({
            //     name : 'selectStaff',
            //     query : {
            //         projectId : row.id,
            //         storeId : this.detail.id,
            //         from : 'shop',
            //     }
            // });
        },
        /**
         * 门店点赞
         */
        handleThumb(){
            ajax.post('storeThumbs',{
                id : this.detail.id,
            }).then( res=> {
                if (res.responeCode==='0') {

                    this.$toast('点赞成功');
                    this.thunbSuccess = true;
                    this.detail.thumbsCnt ++;
                    //组件shop移除缓存
                    this.$store.commit('removeKeepAlive','shop');
                    //更新缓存
                    sessionStorage.setItem('shopDetailInfo',JSON.stringify(this.detail));
                    
                } else {
                    this.$toast(res.responeMsg);
                }
            })
        },
        /**
         * 点击分享
         */
        handleShare(){
            this.getShareInfo({
                tit : this.detail.storeName,
                fxUrl : window.location.href,
                pic : this.detail.coverFullUrl,
            });
        },
        /**
         * 获取分享的内容
         * @param tit 标题
         * @param fxUrl 地址
         * @param pic 图片
         */
        getShareInfo({tit,fxUrl,pic}){
            //获取微信签名信息
            ajax.post('getJsConfig').then((res) => {
                if (res.responeCode == '0' && res.data) {
                    let configData = {
                        debug: false,
                        appId: res.data.appId, // 必填，公众号的唯一标识
                        timestamp: "" + res.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: res.data.noncestr, // 必填，生成签名的随机串
                        signature: res.data.signature,// 必填，签名
                        jsApiList: ['onMenuShareTimeline','onMenuShareAppMessage']
                    }
                    wx.config(configData);
                    wx.ready(()=>{
                        //分享到朋友圈
                        wx.onMenuShareTimeline({
                            title: tit,   // 分享时的标题
                            link: fxUrl,     // 分享时的链接
                            imgUrl: pic,    // 分享时的图标
                            success: function () {
                                console.log("分享成功");
                            },
                            cancel: function () {
                                console.log("取消分享");
                            }
                        });
                        //分享给朋友
                        wx.onMenuShareAppMessage({
                            title: tit,
                            desc: '', 
                            link: fxUrl,
                            imgUrl: pic,
                            type: '',
                            dataUrl: '', 
                            success: function () {
                                console.log("分享成功");
                            },
                            cancel: function () {
                                console.log("取消分享");
                            }
                        });
                    })
                } else {
                    this.$toast("获取签名信息失败，请刷新页面重新获取");
                }
            });
        },
    },
}
</script>
<style scoped lang='scss'>
.page{
    padding-bottom: 30px;
}
.store-detail{
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;
    .top{
        display: flex;
    }
    .store-logo{
        width: 280px;
        height: 280px;
        border-radius: 12px;
        margin-right: 20px;
    }
    .store-name{
        font-size: 34px;
        color: #333;
        font-weight: bold;
        margin-bottom: 15px;
    }
    .store-addr{
        font-size: 28px;
        color: #666;
        margin-bottom: 20px;
    }
    .right-box{
        display: flex;
        flex:1;
        flex-direction: column;
        padding:20px 0;
    }
    ul{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        
        img{
            height: 25px;
            margin-right: 10px;
            display: block;
            margin: 0 auto;
        }
        span{
            color: #999;
            font-size: 20px;
            display: block;
            margin: 0 auto;
        }
    }
    .intro{
        color: #666;
        margin-top: 20px;
        line-height: 40px;
    }
}
.service-items{
    background-color: #fff;
    padding: 30px 20px;
    margin-top: 24px;
    border-radius: 12px;
    .title{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 150px;
        }
        span{
            color: #FF0202;
            font-size: 36px;
            font-weight: bold;
            padding: 0 20px;
        }
    }
    .list{
        margin-top: 20px;
        .item{
            display: flex;
            border-bottom: 1px solid #F5F5F5;
            margin: 0 20px;
            padding: 30px 0;
            img{
                width: 200px;
                height: 200px;
                border-radius: 12px;
                margin-right: 20px;
                flex-shrink: 0;
            }
            h4{
                font-size: 34px;
                color: #333;
                font-weight: bold;
                margin-bottom: 15px;
            }
            p{
                font-size: 28px;
                color: #666;
            }
            .remark{
                overflow: hidden;
                text-overflow: ellipsis; 
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                margin-bottom: 20px;
            }
            &:last-child{
                border-bottom: none;
                padding-bottom: 0;
            }
        }
    }
}
</style>